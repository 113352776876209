import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
  fragment ProyectoQuery on ContentfulProyectoConnection {
    edges {
        node {
            description1 {
                json
            }
        }
    }
}`

const DescriptionSection = ({description}) => {
    return (
        <section className="flex flex-col items-stretch justify-center px-6 py-6 text-gray-800 sm:py-12 sm:px-16 md:py-auto lg:py-20 xxl:min-h-screen" id="section-2">
            <div className='block mx-auto mt-5 leading-7 text-center sm:text-base sm:leading-6 md:text-lg lg:text-xl xl:text-2xl xl:max-w-6xl lg:max-w-5xl md:leading-9 md:px-10 md:max-w-4xl xxl:text-3xl xxl:leading-relaxed'>
                {documentToReactComponents(description)}
            </div>
        </section>
    )
}

DescriptionSection.propTypes = {
    description: PropTypes.object
}

export default DescriptionSection
