import PropTypes from 'prop-types'
import React from 'react'

const argentina = [
    {
        longitud: -76.55273437499999,
        latitud: -55.875310835696816
    },
    {
        longitud: -52.82226562499997,
        latitud: -55.875310835696816
    },
    {
        longitud: -52.82226562499997,
        latitud: -20.879342971957975
    },
    {
        longitud: -76.55273437499999,
        latitud: -20.879342971957975
    }
]

const location = {latitud: -34.5931776, longitud: -58.405683200000006}

const testCoordinates = (point = location, rectangle = argentina) => {
    const cumpleLatitud = point.latitud >= rectangle[0].latitud && point.latitud <= rectangle[3].latitud;
    const cumpleLongitud = point.longitud >= rectangle[0].longitud && point.longitud <= rectangle[1].longitud;

    return cumpleLatitud && cumpleLongitud;
}

testCoordinates.propTypes = {
    point: PropTypes.object,
    rectangle: PropTypes.object
}

export default testCoordinates;