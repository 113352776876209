import PropTypes from 'prop-types'
import React from 'react'
import Img from "gatsby-image/withIEPolyfill"

import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const BioComponent = ({name, description, fluid, index }) => {

    const options = {
        renderNode: {
            // eslint-disable-next-line react/display-name
            [BLOCKS.PARAGRAPH]: (node, children) => <p className="mt-2 text-sm font-light leading-6 md:mt-4 md:text-lg md:leading-8 lg:mt-2">{children}</p>,
            // eslint-disable-next-line react/display-name
            [BLOCKS.HEADING_3]: (node, children) => <h3 className='font-serif text-base font-normal md:text-xl xxl:text-center'>{children}</h3>,
            // eslint-disable-next-line react/display-name
            [INLINES.HYPERLINK]: (node, children) => <a target='_blank' rel="noopener noreferrer" href={node.data.uri} className='font-semibold underline cursor-pointer hover:text-gray-400'>{children}</a>,
        }
    };

    return (
        <div className='flex flex-col w-full lg:block lg:px-20 lg:py-10 xxl:px-0 xxl:py-0 xxl:flex-row xxl:flex'>
            <div className={index == 0? 'w-full lg:w-6/12 xl:pl-0 lg:float-left lg:pr-10 lg:pl-4 lg:pb-2 lg:pt-3 xxl:px-0 xxl:py-0 xxl:float-none' : 'w-full lg:w-1/2 lg:order-2 lg:pr-4 lg:pl-10 lg:pb-2 xl:pr-0 lg:float-right lg:p-4 xxl:px-0 xxl:py-0 xxl:float-none'}>
                <Img fluid={fluid}/>
            </div>
            <div className='flex flex-col items-center justify-center w-full px-6 py-5 mx-auto md:py-16 sm:px-16 md:px-24 md:max-w-4xl lg:w-full lg:px-4 lg:block lg:mx-0 lg:max-w-full lg:py-0 xxl:w-1/2 xxl:flex xxl:flex-col xxl:px-20'>
                <h2 className='font-serif text-2xl font-medium lg:text-4xl md:text-3xl'>
                    {name}
                </h2>
                <div className='block mt-2 lg:text-justify xxl:max-w-6xl'>
                    {documentToReactComponents(description, options)}
                </div>
            </div>
        </div>
    )
}

BioComponent.propTypes = {
    name: PropTypes.string,
    description: PropTypes.object,
    fluid: PropTypes.object,
    index: PropTypes.number
}

export default BioComponent