import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import Testimonial from './testimonial'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const query = graphql`
  fragment TestimoniosQuery on ContentfulTestimoniosConnection {
    edges {
        node {
            testimonial {
                testimonial
            }
            author
            language
        }
    }
}`

const TestimonialsSection = ({testimonials}) => {

    const MySwal = withReactContent(Swal)

    const intl = useIntl()

    const customSwal = MySwal.mixin({
        customClass: {
          confirmButton: 'px-4 py-2 border-2 mx-2 border-white hover:bg-white text-xs md:text-sm text-white hover:text-flyer-blue order-2',
          cancelButton: 'px-4 py-2 border-2 mx-2 border-white hover:bg-white text-xs md:text-sm text-white hover:text-flyer-blue order-1'
        },
        buttonsStyling: false,
        containerStyling: false
      })

    const testimonialsWithoutDuplicatedAuthors = testimonials.filter((value,index,array) => array.findIndex(t => (t.node.author == value.node.author)) === index)

    const testimonialsModal = () => {

        customSwal.mixin({
            backdrop: 'rgba(200,200,200,0.4)',
            //showCloseButton: true,
            confirmButtonText: intl.formatMessage({ id: "next" }),
            cancelButtonText: intl.formatMessage({ id: "close" }),
            showCancelButton: true,
            background: '#436668',
            //width: "98%",
            html: '<div class="text-white">' +
                `<p class="text-white mt-2 text-xs md:text-lg md:leading-8 -mx-4 font-light">${testimonials[0].node.testimonial.testimonial}</p>` +
                `<p class="text-white text-sm font-semibold font-serif text-right mt-5">${testimonials[0].node.author}</p>`  +
                '</div>'
        }).queue(testimonialsWithoutDuplicatedAuthors.map(value => {
            return {
                html: `<p class="mt-2 text-white  md:text-lg md:leading-8 text-sm font-light leading-5 -mx-4">${value.node.testimonial.testimonial}</p>` +
                `<p class="font-semibold font-serif text-sm text-right text-white mt-5">${value.node.author}</p>`,
            }
        }))
    }

    const testimonialModal = (testimonial) => {
        customSwal.fire({
            backdrop: 'rgba(55,55,55,0.4)',
            background: '#436668',
            showCloseButton: true,
            showConfirmButton: false,
            cancelButtonText: intl.formatMessage({ id: "close" }),
            showCancelButton: true,
            html: '<div>' +
                `<p class="mt-2 font-normal text-white  md:font-medium leading-7 sm:leading-8">${testimonial.node.testimonial.testimonial}</p>` +
                `<p class="font-bold text-right text-white mt-5">${testimonial.node.author}</p>`  +
                '</div>'
        })
    }

    const randomSubArray  = (array) => {
        
        const subArray = []

        while(subArray.length < 3){
            const randomIndex = Math.floor(Math.random() * array.length);
            if(subArray.indexOf(randomIndex) === -1) {
                subArray.push(randomIndex);
            }
        }
        
        return subArray.map(randomIndex => array[randomIndex])
    }

    return (
        <section className="flex flex-col items-center justify-start min-h-full py-10 text-center text-gray-200 sm:min-h-0 bg-flyer-blue md:min-h-0 xxl:min-h-screen md:py-20 xxl:flex-auto" id="section-6">
            <h2 className='block font-serif text-xl font-bold uppercase md:text-2xl xl:text-3xl'>
                <FormattedMessage id='testimonials-header'/>
            </h2>
            <div className='flex flex-col items-center justify-center xxl:flex-1 xxl:flex-row sm:px-16 md:px-24 md:max-w-4xl xl:max-w-5xl xl:mt-5 xxl:max-w-full'>
                {randomSubArray(testimonialsWithoutDuplicatedAuthors).map((testimonial, index) => 
                    <Testimonial 
                        className={'flex flex-col content-start justify-center self-center max-w-sm sm:max-w-full xl:w-full mx-4 mt-5 text-center xl:mx-12'}
                        author={testimonial.node.author}
                        text={testimonial.node.testimonial.testimonial}
                        toggleSwal={() => testimonialModal(testimonial)}
                        key={index}/>
                )}
            </div>
            <button className='px-4 py-2 mt-10 text-xs font-semibold border-2 border-white md:text-sm hover:bg-white hover:text-flyer-blue' onClick={() => testimonialsModal()}>
                <FormattedMessage id='see-all' />
            </button>
        </section>
    )
}

TestimonialsSection.propTypes = {
    testimonials: PropTypes.array
}

export default TestimonialsSection