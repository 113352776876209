import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useIntl } from 'gatsby-plugin-intl'


const PaymentAlert = ({ resultOfPayment }) => {
    const MySwal = withReactContent(Swal)
    const intl = useIntl()

    const alertSuccess = () => {
        MySwal.fire({
            icon: 'success',
            title: intl.formatMessage({ id: "payment-success" }),
            showConfirmButton: true
        })
    }

    const alertError = () => {
        MySwal.fire({
            icon: 'error',
            title: intl.formatMessage({ id: "payment-error" }),
            showConfirmButton: true
        })
    }

    useEffect(() => {
        if(resultOfPayment == 'success')
            alertSuccess()
        else if (resultOfPayment == 'failure')
            alertError()
    }, [])
    
    return (<></>)
}

PaymentAlert.propTypes = {
    resultOfPayment: PropTypes.string
}

export default PaymentAlert