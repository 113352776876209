import { geoConstants } from '../constants'
import { geoService } from '../services'
import geoHelper from '../../helpers/geo'

export const geoActions = {
    get
}

function get() {

    return dispatch => {

        dispatch(request())

        if(window.localStorage.getItem('geo')){
            const geo = JSON.parse(window.localStorage.getItem('geo'))

            if(new Date(geo.expires) >= new Date())
                return dispatch(set(geo))
        }


            if("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    function successData(position) {
        
                        const esArgentina = geoHelper({
                            latitud: position.coords.latitude,
                            longitud: position.coords.longitude
                        })
        
                        dispatch(success({
                            country_code: esArgentina? 'AR' : false
                        }))
                    },
                    function errorData(errorMessage) {
                        geoService.get()
                            .then(
                                geo => {
                                    dispatch(success(geo))
                                },
                                error => {
                                    dispatch(failure(error.toString()))
                                }
                            )
                    }
                )
            }
            else {
                geoService.get()
                    .then(
                        geo => {
                            dispatch(success(geo))
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    )
            }
    }

    function request() { return { type: geoConstants.GET_GEO_REQUEST } }
    function success(geo) { return { type: geoConstants.GET_GEO_SUCCESS, geo } }
    function failure(error) { return { type: geoConstants.GET_GEO_FAILURE, error } }
    function set(geo) { return { type: geoConstants.GET_GEO_SET, geo } }
}