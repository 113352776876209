import PropTypes from 'prop-types'
import React, { useState } from 'react'
//import { Video } from 'cloudinary-react'
import ReactPlayer from 'react-player'
import { useIntl } from 'gatsby-plugin-intl'

const VideoComponent = ({children, title, youtubeLink }) => {

    const [showModal, setShowModal] = useState(false)
    const intl = useIntl()

    return (
        <>
            <button
            className="px-4 mt-2 text-white border-2 border-white bg-flyer-blue hover:bg-white hover:text-flyer-blue"
            type="button"
            style={{ transition: "all .15s ease" }}
            onClick={() => setShowModal(true)}
            >
                {intl.formatMessage({ id: "trailer-button" })}
            </button>
            {showModal ? (
            <>
                <div
                className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
                >
                <div className="relative w-full max-w-xs mx-auto my-6 sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
                    {/*content*/}
                    <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                    {/*header*/}
                    {/* <div className="flex items-start justify-between p-5 border-b border-gray-300 border-solid rounded-t">
                        <h3 className="text-3xl font-semibold">
                        {title}
                        </h3>
                        <button
                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                        onClick={() => setShowModal(false)}
                        >
                        <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                            ×
                        </span>
                        </button>
                    </div> */}
                    {/*body*/}
                    <div className="relative">
                        {/* 
                        Cloudinary videos
                        {trailer[0] && trailer[0].public_id? 
                            <Video cloudName="artinmotion" 
                            publicId={trailer[0].public_id} 
                            controls={true}
                            fallbackContent={intl.formatMessage({ id: "video-error" })}>
                            </Video>
                            :
                            ''
                        }    */}
                        <ReactPlayer url={youtubeLink}
                            className='react-player'
                            onEnded={() => setShowModal(false)}
                            controls={true}
                            width='100%'/>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-center py-2 pb-4 border-t border-gray-300 border-solid rounded-b lg:pr-6 lg:justify-end">
                        <button
                        className="px-4 py-2 mx-2 mt-2 text-sm font-semibold bg-white border-2 text-flyer-blue border-flyer-blue md:text-base hover:bg-flyer-blue hover:text-white"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => setShowModal(false)}
                        >
                            {intl.formatMessage({ id: "close-button" })}
                        </button>

                        {children}
                    </div>
                    </div>
                </div>
                </div>
                <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
            </>
            ) : null}
        </>
    );
}

VideoComponent.propTypes = {
    // trailer: PropTypes.array,
    children: PropTypes.node,
    title: PropTypes.string,
    youtubeLink: PropTypes.string
}

export default VideoComponent