import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { graphql } from 'gatsby'

import { useSelector, useDispatch } from 'react-redux'
import { geoActions } from '../redux/actions'

import withLocation from '../wrappers/withLocation'

import Layout from "../templates/layout"
import SEO from "../components/seo"
import Front from '../components/front'
import TestimonialsSection from "../components/testimonialsSection"
import DescriptionSection from "../components/descriptionSection"
import BiographySection from "../components/biographySection"
import LessonsSection from "../components/lessonsSection"
import ContactSection from "../components/contactSection"
import PaymentAlert from "../components/paymentAlert"
import Footer from "../components/footer";


export const query = graphql`
  query Page($locale: String!) {
    allContentfulSeo(filter: { node_locale: {eq: $locale} }) {
      ...SEOQuery
    }
    allContentfulPortada(filter: { node_locale: {eq: $locale}}){
      ...PortadaQuery
    }
    allContentfulProyecto(filter: { node_locale: {eq: $locale}}){
      ...ProyectoQuery
    }
    allContentfulTestimonios(filter: { language: {eq: $locale}}, sort: {order: ASC, fields: id}) {
      ...TestimoniosQuery
    }
    allContentfulBiografia(filter: { node_locale: {eq: $locale}}, sort: {fields: name})  {
      ...BiografiasQuery
    }
    allContentfulContacto(filter: { node_locale: {eq: $locale}}) {
      ...ContactoQuery
    }
    allContentfulClase(filter: { node_locale: { eq: $locale } }, sort: {order: ASC, fields: order}) {
      ...ClasesQuery
    }
    allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
      ...FooterQuery
    }
}
`

const IndexPage = ({data, pageContext, search}) => {

  const dispatch = useDispatch()
  const geo = useSelector(state => state.geo)

  useEffect(() => {
    dispatch(geoActions.get())
  }, [])

  return (
    <Layout>
      <main className="flex-1 w-full mx-auto">
        <SEO
          keywords={[`Tango`, `Yoga`, `Dance`, `Bio-mechanics`]}
          title={data.allContentfulSeo.edges[0].node.title}
          lang={pageContext.intl.language}
          imageUrl={data.allContentfulSeo.edges[0].node.image.file.url}
          description={data.allContentfulSeo.edges[0].node.description.description}
        />

        <Front data={data.allContentfulPortada.edges[0].node}/>

        <DescriptionSection description={data.allContentfulProyecto.edges[0].node.description1.json}/>

        <BiographySection bios={data.allContentfulBiografia.edges}/>

        <LessonsSection lessons={data.allContentfulClase.edges} esArgentina={geo.data && geo.data.esArgentina}/>
        
        <TestimonialsSection testimonials={data.allContentfulTestimonios.edges}/>

        <ContactSection 
          title={data.allContentfulContacto.edges[0].node.tittle} 
          description={data.allContentfulContacto.edges[0].node.tittle}
          fluid={data.allContentfulContacto.edges[0].node.image.localFile.childImageSharp.fluid}
          />

        { search.payment ? 
          <PaymentAlert resultOfPayment={search.payment}/>
          : ''
        }
      </main>

      <Footer description={data.allContentfulFooter.edges[0].node.description.description?? ''} image={data.allContentfulFooter.edges[0].node.imageEmail.localFile.childImageSharp.fluid}/>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  geoData: PropTypes.object,
  front: PropTypes.object,
  search: PropTypes.object,
}

export default withLocation(IndexPage);
