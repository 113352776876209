import PropTypes from 'prop-types'
import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"

export const query = graphql`
  fragment PortadaQuery on ContentfulPortadaConnection {
    edges {
      node{
        buyButton
        tittle
        slogan {
          slogan
        }
        image1{
            localFile{
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        image2{
            localFile{
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
      }
    }
}`

const Front = ({data}) => {
    return (
        <section className="w-full text-gray-900 lg:min-h-screen lg:flex md:flex-row" id="section-1">
            <div className='w-full h-full lg:w-7/12 xl:w-1/2'>
                <div className='grid w-full h-full grid-cols-12 grid-rows-6 px-4 pt-20 bg-flyer-blue lg:min-h-full'>
                    <div className='z-10 col-span-6 row-span-3 h-1/2 lg:col-start-2 lg:mt-12'>
                        <Img fluid={data.image1.localFile.childImageSharp.fluid} className='border-4 border-white'/>
                    </div>
                    <div className='col-span-6 row-span-3 pt-5 pl-1 sm:self-center lg:col-span-5'>
                        <div className='flex flex-col items-center lg:hidden'>
                            <h1 className='block px-1 font-serif text-xl font-bold text-center text-white uppercase sm:text-4xl'>
                                {data.tittle}
                            </h1>
                            <p className='pt-2 pb-3 font-sans text-sm font-medium text-center text-gray-200 sm:text-lg'>
                                {data.slogan.slogan}
                            </p>
                            <p className='w-11/12 mt-5 text-sm font-bold text-center text-white bg-flyer-dark'>
                                {data.tag}
                            </p>
                        </div>
                    </div>
                    <div className='col-span-4 row-span-3 lg:col-span-3'>
                        
                    </div>
                    <div className='col-span-8 row-span-3 lg:col-span-8'>
                        <Img fluid={data.image2.localFile.childImageSharp.fluid} className='-mt-6 border-4 border-white lg:-mt-12 xl:-mt-12'/>
                    </div>
                    <div className='col-span-12 row-span-1 mb-8 -mt-12'>
                        <button onClick={() => scrollTo('#section-4')} className="block px-4 py-2 mx-auto mt-4 text-xs font-semibold text-white border-2 border-white md:text-lg lg:hidden bg-flyer-blue" href="#section-4">
                            {data.buyButton}
                        </button>
                    </div>
                </div>
            </div>
            <div className='items-center justify-center hidden h-full lg:flex-col lg:flex lg:w-5/12 xl:w-1/2'>
                <h1 className='block font-serif text-6xl font-bold text-center uppercase text-flyer-blue'>
                    {data.tittle}
                </h1>
                <p className='mt-10 text-2xl text-center'>
                    {data.slogan.slogan}
                </p>
                <button onClick={() => scrollTo('#section-4')} 
                    className="block px-4 py-2 mt-10 font-semibold border-2 text-flyer-blue border-flyer-blue hover:bg-flyer-blue hover:text-white" href="#section-4">
                        {data.buyButton}
                </button>
            </div>
        </section>
    )
}

Front.propTypes = {
    data: PropTypes.object
}

export default Front