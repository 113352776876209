/*eslint no-undef: 0*/
import axios from 'axios'

const baseUrl = `https://geolocation-db.com/json/`

export const geoService = {
    get
}

function get() {
    
    return axios.get(baseUrl)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return Promise.reject(error)
        })
}