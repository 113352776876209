import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage } from "gatsby-plugin-intl";

const Testimonial = ({author, text, className, place, toggleSwal}) => {

    const [isShowed, show] = useState(false);

    const FullText = () => 
        (<p className='mt-2 text-sm leading-5 md:text-lg md:leading-8 xxl:hidden'>
            {text.length > 320 && !isShowed? text.slice(0, 280) + '...' : text}
        </p>)

    const FullTextXl = () => 
    (<p className='hidden mt-2 text-sm leading-5 md:text-lg md:leading-8 xxl:block'>
        {text.length > 600 && !isShowed? text.slice(0, 500) + '...' : text}
    </p>)
  
    return (
        <div className={className}>
            <FullText />
            <FullTextXl />
            <div className='mx-auto xxl:hidden'>
                {
                    text.length > 320 && !isShowed? 
                        <>
                            <button className='block px-4 py-2 mt-3 text-xs font-semibold border-2 bg-flyer-blue hover:text-flyer-blue hover:bg-white md:text-sm' onClick={() => show(true)}>
                                <FormattedMessage id="read_complete" />
                            </button>
                            {/* <button className='hidden px-4 py-2 mt-3 text-xs font-semibold border-2 bg-flyer-blue hover:text-flyer-blue hover:bg-white md:block' onClick={toggleSwal}>
                                <FormattedMessage id="read_complete" />
                            </button> */}
                        </>
                        : ''
                }
            </div>
            <div className='hidden mx-auto xxl:block'>
                {
                    text.length > 600 && !isShowed? 
                        <>
                            <button className='block px-4 py-2 mt-3 text-xs font-semibold border-2 bg-flyer-blue hover:text-flyer-blue hover:bg-white md:text-sm' onClick={() => show(true)}>
                                <FormattedMessage id="read_complete" />
                            </button>
                        </>
                        : ''
                }
            </div>

            <h3 className='block mt-4 mr-4 font-serif text-sm font-semibold text-right md:text-base'>{author}</h3>
        </div>
    )
}

Testimonial.propTypes = {
    author: PropTypes.string,
    text: PropTypes.string,
    place: PropTypes.string,
    className: PropTypes.string,
    toggleSwal: PropTypes.func
}

export default Testimonial