import PropTypes from 'prop-types'
import React from 'react'

import { Link, graphql } from 'gatsby'

import LessonCard from './lessonCard'

export const query = graphql`
  fragment ClasesQuery on ContentfulClaseConnection {
    edges {
        node {
            title
            description {
              description
            }
            linkMercadopago
            linkPaypal
            # trailer {
            #   public_id
            # }
            youtubeLink
            image {
              localFile{
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
            }
            paypalPrice
            mercadopagoPrice
        }
    }
}`

const LessonsSection = ({lessons, esArgentina}) => {
  
    return (
        <section className="flex flex-col items-center justify-center min-h-screen px-5 py-12 text-gray-900 sm:min-h-0" id="section-4">
        <h2 className='block font-serif text-xl font-semibold uppercase md:text-2xl text-flyer-blue xl:text-3xl'>
          Clases
        </h2>
        
        <div className='grid w-full grid-cols-12 gap-2 mt-12 md:gap-12 md:px-5 lg:gap-20 lg:px-12 xl:gap-6'>
          { lessons.map((lesson, index) => {
              return <LessonCard 
                key={index}
                title={lesson.node.title}
                description={lesson.node.description.description}
                image={lesson.node.image.localFile.childImageSharp.fluid?? {}}
                // trailer={lesson.node.trailer}
                youtubeLink={lesson.node.youtubeLink}
                linkMercadopago={lesson.node.linkMercadopago}
                linkPaypal={lesson.node.linkPaypal}
                esArgentina={esArgentina}
                paypalPrice={lesson.node.paypalPrice}
                mercadopagoPrice={lesson.node.mercadopagoPrice}
              />
            }) 
          }
        </div>
      </section>
    )
}

LessonsSection.propTypes = {
  lessons: PropTypes.array,
  esArgentina: PropTypes.bool
}

export default LessonsSection