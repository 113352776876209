import PropTypes from 'prop-types'
import React from 'react'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useIntl } from 'gatsby-plugin-intl'
import axios from 'axios'

const endpoints = {
    contact: "/.netlify/functions/sendEmail",
  }
//const axios = require("axios")


const ContactForm = () => {

    const MySwal = withReactContent(Swal)

    const intl = useIntl()

    const handleSubmit = sumbittedData => {
        let { name, email, message } = sumbittedData
        let data = { name, email, message }
        // console.log(data)
        // console.log(JSON.stringify(data))
        axios({
            method: 'post',
            url: endpoints.contact,
            data: JSON.stringify(data),
            dataType: 'json',
            responseType: 'json'
          })
          //.post(endpoints.contact, JSON.stringify(data))
            .then(response => {
                if (response.status !== 200) {
                    return handleError()
                } else {
                    return handleSuccess()
                }
            }).catch((error) => {
                if (error.status !== 200) {
                    return handleError()
                } else {
                    return handleSuccess()
                }
            })
    }

    const handleSuccess = () => {
        MySwal.fire({
            icon: 'success',
            title: intl.formatMessage({ id: "email-success" }),
            showConfirmButton: false,
            timer: 1500
        })
    }

    const handleError = () => {
        MySwal.fire({
            icon: 'error',
            title: intl.formatMessage({ id: "email-error" }),
            showConfirmButton: false,
            timer: 1500
        })
    }

    const MyTextInput = ({ label, ...props }) => {
        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
        // which we can spread on <input> and also replace ErrorMessage entirely.   
        const [field, meta] = useField(props)
     
        return (<>
            <label className="block w-full text-sm font-bold tracking-wide lg:text-sm xl:text-sm text-flyer-blue" htmlFor={props.id || props.name}>
                {label}
            </label>
            <input id={props.name} className="block w-full px-4 py-3 mb-1 text-sm leading-tight text-gray-700 bg-gray-100 border-2 appearance-none border-flyer-blue text-input focus:outline-none focus:bg-white" {...field} {...props} />
            {meta.touched && meta.error ? 
                (<p className="text-sm italic text-red-700">{meta.error}</p>) : null}
        </>)
    }

    MyTextInput.propTypes = {
        label: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.number
    }

    const MyTextarea = ({ label, ...props }) => {
        const [field, meta] = useField(props)
        return (<>
            <label className="block mb-2 text-sm font-bold tracking-wide lg:text-sm xl:text-sm text-flyer-blue" 
                htmlFor={props.id || props.name}>
                    {label}
            </label>
            <textarea id={props.name} className="block w-full px-4 py-3 mb-1 text-sm leading-tight text-gray-700 bg-gray-100 border-2 appearance-none border-flyer-blue text-input focus:outline-none focus:bg-white" 
                {...field} {...props} rows='5'/>

            {meta.touched && meta.error ? 
                (<div className="border-red-700">{meta.error}</div>) : null }
        </>)
    }

    MyTextarea.propTypes = {
        label: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.number
    }

    const form = formik => {
        return (<Form onSubmit={formik.handleSubmit} className='w-full sm:max-w-lg md:max-w-4xl md:px-24 lg:px-0 xxl:mt-20'>
            <div className='flex flex-col flex-wrap justify-center w-full mt-5 md:flex-row'>
                <div className='w-full px-3 md:w-1/2 md:mb-0'>
                    <MyTextInput
                        label={intl.formatMessage({ id: "form-name" })}
                        name="name"
                        type="text"
                        placeholder={intl.formatMessage({ id: "form-name" })}
                        />
                </div>
                <div className='w-full px-3 mt-2 md:w-1/2 md:mt-0'>
                    <MyTextInput
                        label={intl.formatMessage({ id: "form-email" })}
                        name="email"
                        type="email"
                        placeholder={intl.formatMessage({ id: "placeholder-email" })}
                        />
                </div>
                <div className='w-full px-3 mt-2 md:mt-4 xxl:mt-20'>
                    <MyTextarea label={intl.formatMessage({ id: "form-message" })} name="message"/>
                </div>
                <button className='px-2 py-2 mx-20 mt-5 text-sm font-semibold border-2 lg:text-sm xl:text-base border-flyer-blue text-flyer-blue hover:bg-flyer-blue hover:text-white lg:mt-2 xl:mt-5'>
                    Enviar
                </button>
            </div>
        </Form>)
    }

    return (
        <Formik
            initialValues={{ 
                name: '', 
                email: '', 
                message: ''
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                  .max(50, 'Máximo 20 caracteres')
                  .min(3, 'Mínimo 3 caracteres')
                  .required('Required field'),
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Required field'),
                message: Yup.string()
                    .max(1000, '1000 characters max')
              })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                handleSubmit(values)
            }}>
                {form}
        </Formik>
    )
}

export default ContactForm