import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

import Img from "gatsby-image/withIEPolyfill"
import ContactForm from './contactForm'

export const query = graphql`
  fragment ContactoQuery on ContentfulContactoConnection {
    edges {
        node {
            tittle
            description {
                description
            }
            image{
                localFile{
                    childImageSharp {
                        fluid {
                        ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
}`

const ContactSection = ({title, description, fluid}) => {
    return (
        <section className="text-center text-gray-800 bg-white" id="section-7">
            <div className='flex flex-col w-full lg:flex-row'>
                <div className='w-full lg:w-1/2'>
                    <Img fluid={fluid} />
                </div>
                <div className='flex flex-col items-center justify-around w-full px-2 my-5 lg:px-10 lg:w-1/2 lg:justify-center md:py-12 lg:py-0 xxl:max-w-4xl xxl:mx-auto xxl:justify-start'>
                    <h3 className='font-serif text-xl font-semibold uppercase md:text-2xl text-flyer-blue lg:text-2xl xl:text-4xl'>
                        {title}
                    </h3>
                    {/* <p className='block text-xl'>
                        {description}
                    </p> */}
                    <ContactForm />
                </div>
            </div>
        </section>
    )
}

ContactSection.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    fluid: PropTypes.object
}

export default ContactSection