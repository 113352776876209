import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

import BioComponent from './bioComponent'

export const query = graphql`
  fragment BiografiasQuery on ContentfulBiografiaConnection {
    edges {
        node {
            name
            bio {
                json
            }
            image2{
                localFile{
                    childImageSharp {
                        fluid {
                        ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
}`

const BiographySection = ({bios}) => {
    return (
        <section className="min-h-screen text-center text-white bg-flyer-blue xl:flex xl:flex-col" id="section-3">
            { bios.map((edge, index) => {
                return <BioComponent 
                    fluid={edge.node.image2.localFile.childImageSharp.fluid} 
                    name={edge.node.name}
                    description={edge.node.bio.json}
                    key={index}
                    index={index}
                    />
            })}
        </section>
    )
}

BiographySection.propTypes = {
    bios: PropTypes.array
}

export default BiographySection
